<template>
  <div class="together-dia">
    <el-dialog
      :title="'设置撮合扣款次数—'+ currentRow.matchChannelName"
      :visible="dialogVisible"
      :close-on-click-modal="false"
      width="800px"
      @close="close"
    >
      <div style="margin-bottom: 10px;">
        <el-button
          size="small"
          type="success"
          icon="el-icon-plus"
          @click="addTemplate"
          >新增模板</el-button
        >
      </div>
      <div v-loading="loading">
        <el-tabs
          v-model="tabsValue"
          @tab-remove="removeTab"
          @tab-click="tabClick"
        >
          <el-tab-pane
            v-for="(item, index) in templateList"
            :key="item.id"
            :label="item.templateName"
            :name="item.templateName"
            :closable="index !== 0"
          >
          </el-tab-pane>
        </el-tabs>
        <div>
          <span>使用规则：</span>
          <span style="color: #333333;">{{templateRules}}</span>
        </div>
        <div
          style="height: 40px;display: flex;align-items: center;justify-content: space-between;"
        >
          <span>扣款条件</span>
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-plus"
            @click="addRule"
            >添加规则</el-button
          >
        </div>
        <GlobalTable
          style="margin-bottom:22px"
          ref="GlobalTable"
          myKey="deduction"
          :maxHeight="270"
          :columns="deductionColumns"
          :isPagination="false"
          :data="middleRegionVos"
        >
          <el-table-column label="成交订单报价" slot="rank" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.lower }}≤区间＜{{ row.upper }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            :label-width="'200px'"
            slot="operation"
            align="center"
          >
            <template slot-scope="{ row, $index }">
              <el-button
                size="mini"
                type="primary"
                round
                @click="handleBtn(row, 'edit', $index)"
                >编辑</el-button
              >
              <el-button
                size="mini"
                type="danger"
                round
                @click="handleBtn(row, 'del', $index)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </GlobalTable>
        <div style="margin-top: 20px;">最近修改：<span v-if="operateUser.updateAdminAccount">{{operateUser.updateAdminName}}-{{operateUser.updateAdminAccount}}（{{operateUser.updateTime}}）</span><span v-else>暂无</span></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="close">取消</el-button>
        <el-button type="primary" :loading="handleBtnLoading" @click.native="handleSuccess">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="新增模板"
      :visible.sync="addTemplateVisible"
      width="400px"
      :close-on-click-modal="false"
      @closed="addTemplateClose"
    >
      <div class="flex mb-10">
        <span class="label-S"><span class="label-must">*</span>模板名称</span>
        <el-input
          size="small"
          placeholder="请输入模板名称,最多10个字"
          v-model.trim="templateName"
          maxlength="10"
        >
        </el-input>
      </div>
      <div class="flex mb-10">
        <span class="label-S"><span class="label-must">*</span>使用规则</span>
        <el-input
          size="small"
          type="textarea"
          placeholder="请输入使用规则"
          v-model.trim="inputRuleName"
          maxlength="50"
          show-word-limit
        >
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addTemplateVisible = false">取 消</el-button>
        <el-button type="primary" @click="addTemplateSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="dialogMiddleRegionTitle"
      :visible.sync="dialogMiddleRegionVisible"
      :close-on-click-modal="false"
      width="550px"
      @closed="middleRegionDialogClosed"
    >
      <div class="rules-form">
        <div class="rule-title">
          <img class="tip_ico" src="@/assets/images/ico_ts_red.png" alt />
          <span>说明:按照撮合商家合作后成交订单报价进行扣款</span>
        </div>
        <el-form
          :model="middleRegion"
          :rules="middleRegionRules"
          ref="middleRegion"
          label-width="82px"
        >
          <el-form-item label="报价区间:" required>
            <el-col :span="11">
              <el-form-item prop="lower">
                <el-input
                  v-model.number="middleRegion.lower"
                  maxlength="5"
                  oninput="this.value=value.replace(/[^0-9]/g,'')"
                  placeholder="输入起始值"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col style="text-align: center" :span="2">~</el-col>
            <el-col :span="11">
              <el-form-item prop="upper">
                <el-input
                  v-model.number="middleRegion.upper"
                  maxlength="5"
                  oninput="this.value=value.replace(/[^0-9]/g,'')"
                  placeholder="输入结束值"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label="渠道扣款" prop="channelTax">
            <el-input
              placeholder="请输入0或正整数"
              maxlength="10"
              v-model.trim="middleRegion.channelTax"
              @input="
                moneyInput(middleRegion, 'channelTax', middleRegion.channelTax)
              "
            >
              <template slot="append">次（收货次数）</template>
            </el-input>
          </el-form-item>
          <el-form-item label="繁星扣款" prop="btstarsTax">
            <el-input
              placeholder="请输入0或正整数"
              maxlength="10"
              v-model.trim="middleRegion.btstarsTax"
              @input="
                moneyInput(middleRegion, 'btstarsTax', middleRegion.btstarsTax)
              "
            >
              <template slot="append">次（收货次数）</template>
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="dialogMiddleRegionVisible = false"
          >取消</el-button
        >
        <el-button type="primary" @click.native="middleRegHandleSuccess"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    currentRow: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      loading: false,
      tabsValue: "",
      templateList: [
        {
          templateName: "通用模板",
          templateRules: "渠道通用",
          regionList: []
        },
      ],
      operateUser: {
        updateAdminName: "",
        updateAdminAccount: "",
        updateTime: "",
      },
      templateRules: "渠道通用",
      inputRuleName: "",
      middleRegionVos: [],
      templateName: "",
      handleBtnLoading: false,
      addTemplateVisible: false,
      deductionColumns: [
        { slotName: "rank" },
        { label: "渠道扣款(次/收货次数)", prop: "channelTax" },
        { label: "繁星扣款(次/收货次数)", prop: "btstarsTax" },
        { slotName: "operation" },
      ],
      middleRegion: {
        lower: "",
        upper: "",
        btstarsTax: "",
        channelTax: "",
      },
      middleRowId: "",
      tableOperation: "",
      dialogMiddleRegionTitle: "",
      dialogMiddleRegionVisible: false,
      middleRegionRules: {
        lower: [
          { required: true, message: "请输入报价区间起始值", trigger: "blur" },
        ],
        upper: [
          { required: true, message: "请输入报价区间结束值", trigger: "blur" },
        ],
        channelTax: [
          { required: true, message: "请输入渠道扣款", trigger: "blur" },
        ],
        btstarsTax: [
          { required: true, message: "请输入繁星扣款", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getTemplateList()
  },
  methods: {
    // 获取模板列表
    getTemplateList() {
      this.loading = true
      _api.getTemplateList({id:this.currentRow.id}).then(res => {
        if(res.code === 1){
          this.templateList = res.data
          this.tabsValue = res.data[0].templateName
          this.templateRules = res.data[0].templateName
          this.middleRegionVos = res.data[0].regionList
          this.operateUser = {
            updateAdminName: res.data[0].updateAdminName,
            updateAdminAccount: res.data[0].updateAdminAccount,
            updateTime: res.data[0].updateTime,
          }
          this.loading = false
        }
      })
    },
    close() {
      this.$emit("close");
    },
    // 新增模板
    addTemplate() {
      if(this.templateList.length >= 10){
        this.$message.error('最多可添加10个模板')
        return
      }
      this.addTemplateVisible = true;
    },
    addTemplateClose() {
      this.templateName = "";
      this.inputRuleName = "";
    },
    removeTab(targetName) {
      let tipText = `确认要删除该模板的全部数据吗？`
      this.$confirm(tipText, '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let tabs = this.templateList;
        this.templateList = tabs.filter(tab => tab.templateName !== targetName);
        this.tabsValue = this.templateList[0].templateName
        this.templateRules = this.templateList[0].templateRules
      })
    },
    tabClick(e) {
      this.templateList.forEach(item => {
        if(item.templateName === e.name){
          this.tabsValue = item.templateName
          this.templateRules = item.templateRules
          this.middleRegionVos = JSON.parse(JSON.stringify(item.regionList))
          this.operateUser = {
            updateAdminName: item.updateAdminName,
            updateAdminAccount: item.updateAdminAccount,
            updateTime: item.updateTime,
          }
        }
      })
    },
    addTemplateSubmit() {
      if(!this.templateName.trim()){
        return this.$message.error('请输入模板名称')
      }
      if(!this.inputRuleName.trim()){
        return this.$message.error('请输入使用规则')
      }
      let ruleNameArr = []
      this.templateList.forEach(item => {
        ruleNameArr.push(item.templateName)
      })
      if(ruleNameArr.includes(this.templateName)){
        return this.$message.error('规则名称不能重复')
      }
      let addbj = {
        templateName: this.templateName,
        templateRules: this.inputRuleName,
        regionList: JSON.parse(JSON.stringify(this.templateList[0].regionList))
      }
      this.templateList.push(addbj);
      this.addTemplateVisible = false
    },
    // 添加扣款规则
    addRule() {
      this.tableOperation = "add";
      this.middleRowId = "";
      this.dialogMiddleRegionTitle = "添加规则";
      this.dialogMiddleRegionVisible = true;
    },
    // 扣款条件表格操作
    handleBtn(row, type, index) {
      this.middleRowId = index;
      this.tableOperation = type;
      if (type == "edit") {
        this.dialogMiddleRegionTitle = "编辑规则";
        this.middleRegion.lower = row.lower;
        this.middleRegion.upper = row.upper;
        this.middleRegion.btstarsTax = row.btstarsTax;
        this.middleRegion.channelTax = row.channelTax;
        this.dialogMiddleRegionVisible = true;
      } else {
        this.$confirm("确认要删除该数据吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.middleRegionVos.splice(this.middleRowId, 1);
          this.templateList.forEach(item => {
            if(item.templateName === this.tabsValue){
              item.regionList = JSON.parse(JSON.stringify(this.middleRegionVos))
            }
          })
        });
      }
    },
    middleRegionDialogClosed() {
      this.middleRegion = {
        lower: "",
        upper: "",
        btstarsTax: "",
        channelTax: "",
      };
      this.$refs["middleRegion"].resetFields();
    },
    moneyInput(obj, prop, value) {
      value = "" + value.replace(/[^0-9]/g, "");
      if (!value) {
        this.$set(obj, prop, "");
        return;
      }
      value = parseInt(value);
      this.$set(obj, prop, value);
    },
    // 添加规则-撮合扣款弹框确定
    middleRegHandleSuccess() {
      this.$refs["middleRegion"].validate((valid) => {
        if (valid) {
          let middleRegion = {
            lower: Number(this.middleRegion.lower),
            upper: Number(this.middleRegion.upper),
            btstarsTax: Number(this.middleRegion.btstarsTax),
            channelTax: Number(this.middleRegion.channelTax),

          };
          if (middleRegion.lower > middleRegion.upper) {
            this.$message.error("起始值不能大于结束值");
            return;
          }
          if (middleRegion.lower == middleRegion.upper) {
            this.$message.error("起始值不能和结束值相等");
            return;
          }
          if(this.middleRegionVos.length && this.tableOperation !== 'edit'){
            for (let i = 0; i < this.middleRegionVos.length; i++) {
              const item = this.middleRegionVos[i];
              if (
                (middleRegion.lower >= item.lower && middleRegion.lower < item.upper) ||
                (middleRegion.upper > item.lower && middleRegion.upper <= item.upper) ||
                (middleRegion.lower <= item.lower && middleRegion.upper >= item.upper)
              ) {
                this.$message.error("区间有重复！");
                return;
              }
            }
          }
          
          let params = {
            lower: middleRegion.lower,
            upper: middleRegion.upper,
            btstarsTax: middleRegion.btstarsTax,
            channelTax: middleRegion.channelTax,
          };
          if (this.middleRowId.toString()) {
            this.middleRegionVos.splice(this.middleRowId, 1, params);
          } else {
            this.middleRegionVos.push(params);
          }
          this.middleRegionVos.sort((a, b) => Number(a.lower) - Number(b.lower));
          this.templateList.forEach(item => {
            if(item.templateName === this.tabsValue){
              item.regionList = JSON.parse(JSON.stringify(this.middleRegionVos))
            }
          })
          this.dialogMiddleRegionVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleSuccess() {
      this.templateList
      let params = {
        id: this.currentRow.id,
        templateList: this.templateList,
      }
      this.handleBtnLoading = true
      _api.saveTemplate(params).then(res => {
        if(res.code === 1){
          this.$message.success(res.msg || '操作成功')
          this.close()
        }
      }).finally(() => {
        this.handleBtnLoading = false
      });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.together-dia {
  .flex {
    display: flex;
    align-items: center;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .rules-form {
    .rule-title {
      display: flex;
      align-items: center;
      color: #ff8080;
      margin-bottom: 20px;
    }
    .tip_ico {
      width: 14px;
      height: 14px;
      margin-right: 5px;
    }
    /deep/.el-input-group__append {
      border: 1px solid #0981ff;
      background: #0981ff;
      color: #ffffff;
    }
  }
  .label-S {
    min-width: 70px;
    text-align: right;
    margin-right: 20px;
    line-height: 32px;
    .label-must {
      color: #ff687b;
      margin-right: 4px;
    }
  }
}
</style>
