<template>
  <div class="together-dia">
    <el-dialog
      :title="'设置渠道服务费—'+ currentRow.matchChannelName"
      :visible="dialogVisible"
      :close-on-click-modal="false"
      width="800px"
      @close="close"
    >
      <div style="margin-bottom: 10px;">
        <el-button
          size="small"
          type="success"
          icon="el-icon-plus"
          @click="addTemplate"
          >新增模板</el-button
        >
      </div>
      <div v-loading="loading">
        <el-tabs
          v-model="tabsValue"
          @tab-remove="removeTab"
          @tab-click="tabClick"
        >
          <el-tab-pane
            v-for="(item, index) in templateList"
            :key="item.id"
            :label="item.templateName"
            :name="item.templateName"
            :closable="index !== 0"
          >
          </el-tab-pane>
        </el-tabs>
        <div>
          <span>使用规则：</span>
          <span style="color: #333333;">{{templateRules}}</span>
        </div>
        <div
          style="height: 40px;display: flex;align-items: center;justify-content: space-between;"
        >
          <span>渠道服务费规则</span>
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-plus"
            @click="addRule('add')"
            >添加规则</el-button
          >
        </div>
        <el-table
          border
          stripe
          size="small"
          :maxHeight="500"
          :data="regionList"
          :isPagination="false"
        >
          <!-- 序号 -->
          <el-table-column label="序号" type="index" width="50" align="center"> </el-table-column>
          <!-- 回收商原始报价 -->
          <el-table-column label="回收商原始报价" align="center">
            <template slot-scope="{ row }">
              <div>{{ row.lower }} ≤区间&lt; {{ row.upper }}</div>
            </template>
          </el-table-column>
          <el-table-column label="服务费比例" prop="diffFund" align="center">
            <template slot-scope="{ row }">
              <div>{{ row.matchServerFee }}%</div>
            </template>
          </el-table-column>
          <el-table-column label="服务费上限" prop="diffFundLimit" align="center">
            <template slot-scope="{ row }">
              <div>{{ row.matchServerFeeLimit }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="{ row, $index }">
              <el-button
                size="mini"
                type="primary"
                round
                @click="handleBtn(row, 'edit', $index)"
                >编辑</el-button
              >
              <el-button
                size="mini"
                type="danger"
                round
                @click="handleBtn(row, 'del', $index)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 20px;">最近修改：<span v-if="operateUser.updateAdminAccount">{{operateUser.updateAdminName}}-{{operateUser.updateAdminAccount}}（{{operateUser.updateTime}}）</span><span v-else>暂无</span></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="close">取消</el-button>
        <el-button type="primary" :loading="handleBtnLoading" @click.native="handleSuccess">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="新增模板"
      :visible.sync="addTemplateVisible"
      width="400px"
      :close-on-click-modal="false"
      @closed="addTemplateClose"
    >
    <div class="flex mb-10">
        <span class="label-S"><span class="label-must">*</span>模板名称</span>
        <el-input
          size="small"
          placeholder="请输入模板名称,最多10个字"
          v-model.trim="templateName"
          maxlength="10"
        >
        </el-input>
      </div>
      <div class="flex mb-10">
        <span class="label-S"><span class="label-must">*</span>使用规则</span>
        <el-input
          size="small"
          type="textarea"
          placeholder="请输入使用规则"
          v-model.trim="inputRuleName"
          maxlength="50"
          show-word-limit
        >
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addTemplateVisible = false">取 消</el-button>
        <el-button type="primary" @click="addTemplateSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="ruleTitle"
      :visible.sync="addRuleShow"
      :close-on-click-modal="false"
      width="500px"
      @closed="addRuleClosed"
    >
      <el-form
        class="rules-form"
        :model="editPriceForm"
        :rules="editPricerules"
        ref="editPriceRulesForm"
        label-width="83px"
      >
        <el-form-item label="回收商报价:" required label-width="100px">
          <el-col :span="11">
            <el-form-item prop="lower">
              <el-input
                v-model.number="editPriceForm.lower"
                maxlength="5"
                oninput="this.value=value.replace(/[^0-9]/g,'')"
                placeholder="报价区间开始值"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col style="text-align: center" :span="2">~</el-col>
          <el-col :span="11">
            <el-form-item prop="upper">
              <el-input
                v-model.number="editPriceForm.upper"
                maxlength="5"
                oninput="this.value=value.replace(/[^0-9]/g,'')"
                placeholder="报价区间结束值"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item
          label="服务费比例:"
          prop="matchServerFee"
          label-width="100px"
        >
          <el-input
            min="0"
            max="100"
            placeholder="输入服务费比例"
            v-model.trim="editPriceForm.matchServerFee"
            @input="limitInput($event, 'matchServerFee')"
            @blur="inputBlur('matchServerFee')"
          >
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
        <el-form-item
          label-width="100px"
          label="服务费上限:"
          prop="matchServerFeeLimit"
        >
          <el-input
            placeholder="输入服务费上限"
            min="0"
            maxlength="5"
            v-model.trim="editPriceForm.matchServerFeeLimit"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="addRuleShow = false">取消</el-button>
        <el-button type="primary" @click.native="addRuleSure()">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    currentRow: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    let checkNum = (rule, value, callback) => {
      if (value == 0 || value >= 100) {
        callback(new Error("服务费比例必须大于0小于100"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      tabsValue: "通用模板",
      templateList: [
        {
          id: "1",
          templateName: "通用模板",
          templateRules: "渠道通用",
          regionList: []
        },
      ],
      operateUser: {
        updateAdminName: "",
        updateAdminAccount: "",
        updateTime: "",
      },
      templateRules: "渠道通用",
      regionList: [],
      templateName: "",
      inputRuleName: "",
      addTemplateVisible: false,
      handleBtnLoading: false,
      editPriceForm: {
        //服务费添加规则传参
        lower: "",
        upper: "",
        matchServerFee: "",
        matchServerFeeLimit: "",
      },
      middleRowId: "",
      tableOperation: "",
      ruleTitle: "",
      addRuleShow: false,
      // 规则
      editPricerules: {
        lower: [
          { required: true, message: "请输入报价区间起始值", trigger: "blur" },
        ],
        upper: [
          { required: true, message: "请输入报价区间结束值", trigger: "blur" },
        ],
        matchServerFee: [
          { required: true, message: "请输入服务费比例", trigger: "blur" },
          { type: "number", validator: checkNum, trigger: "blur" },
        ],
        matchServerFeeLimit: [
          {
            required: true,
            message: "请输入服务费上限",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getTemplateList()
  },
  methods: {
    // 获取模板列表
    getTemplateList() {
      this.loading = true
      _api.getServeTemplateList({id:this.currentRow.id}).then(res => {
        if(res.code === 1){
          this.templateList = res.data
          this.tabsValue = res.data[0].templateName
          this.templateRules = res.data[0].templateRules
          this.regionList = res.data[0].regionList
          this.operateUser = {
            updateAdminName: res.data[0].updateAdminName,
            updateAdminAccount: res.data[0].updateAdminAccount,
            updateTime: res.data[0].updateTime,
          }
          this.loading = false
        }
      })
    },
    close() {
      this.$emit("close");
    },
    // 新增模板
    addTemplate() {
      if(this.templateList.length >= 10){
        this.$message.error('最多可添加10个模板')
        return
      }
      this.addTemplateVisible = true;
    },
    addTemplateClose() {
      this.templateName = "";
      this.inputRuleName = "";
    },
    removeTab(targetName) {
      let tipText = `确认要删除该模板的全部数据吗？`
      this.$confirm(tipText, '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let tabs = this.templateList;
        this.templateList = tabs.filter(tab => tab.templateName !== targetName);
        this.tabsValue = this.templateList[0].templateName
        this.templateRules = this.templateList[0].templateRules
        this.regionList = JSON.parse(JSON.stringify(this.templateList[0].regionList))
      })
    },
    tabClick(e) {
      this.templateList.forEach(item => {
        if(item.templateName === e.name){
          this.tabsValue = item.templateName
          this.templateRules = item.templateRules
          this.regionList = JSON.parse(JSON.stringify(item.regionList))
          this.operateUser = {
            updateAdminName: item.updateAdminName,
            updateAdminAccount: item.updateAdminAccount,
            updateTime: item.updateTime,
          }
        }
      })
    },
    addTemplateSubmit() {
      if(!this.templateName.trim()){
        return this.$message.error('请输入模板名称')
      }
      if(!this.inputRuleName.trim()){
        return this.$message.error('请输入使用规则')
      }
      let ruleNameArr = []
      this.templateList.forEach(item => {
        ruleNameArr.push(item.templateName)
      })
      if(ruleNameArr.includes(this.templateName)){
        return this.$message.error('规则名称不能重复')
      }
      let addbj = {
        templateName: this.templateName,
        templateRules: this.inputRuleName,
        regionList: JSON.parse(JSON.stringify(this.templateList[0].regionList))
      }
      this.templateList.push(addbj);
      this.addTemplateVisible = false
    },
    // 添加扣款规则
    addRule() {
      this.tableOperation = "add";
      this.middleRowId = "";
      this.ruleTitle = "添加规则";
      this.addRuleShow = true;
    },
    // 规则
    limitInput(value, name) {
      let str = (value && value.split("")) || [];
      let reg1 = /\d/;
      let reg2 = /\./;
      // 第一个字符不能为小数点
      if (str[0] == ".") {
        this.editPriceForm[name] = "";
        return;
      }
      // 过滤掉除数字和小数点外的字符
      value = str.filter((e) => reg1.test(e) || reg2.test(e));
      // 匹配小数点后只能有1位小数
      let valJoin = value.join("");
      // this.editPriceForm[name] =value.replace(/[^0-9]/g,'')
      this.editPriceForm[name] = valJoin.match(/^\d*(\.?\d{0,1})/g)[0] || "";
      if (this.editPriceForm[name] >= 100) {
        this.editPriceForm[name] = "100";
      }
    },
    // 失焦
    inputBlur(name) {
      console.log(this.editPriceForm[name]);
      if (
        this.editPriceForm[name].toString().indexOf(".") ==
        this.editPriceForm[name].length - 1
      ) {
        this.editPriceForm[name] = this.editPriceForm[name].replace(".", "");
      }
    },
    // 扣款条件表格操作
    handleBtn(row, type, index) {
      this.middleRowId = index;
      this.tableOperation = type;
      if (type == "edit") {
        //编辑规则
        this.ruleTitle = "编辑规则";
        //重置数据
        this.editPriceForm.lower = row.lower;
        this.editPriceForm.upper = row.upper;
        this.editPriceForm.matchServerFee = row.matchServerFee;
        this.editPriceForm.matchServerFeeLimit = row.matchServerFeeLimit;
        this.addRuleShow = true;
      } else {
        this.$confirm("确认要删除该数据吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.regionList.splice(this.middleRowId, 1);
          this.templateList.forEach(item => {
            if(item.templateName === this.tabsValue){
              item.regionList = JSON.parse(JSON.stringify(this.regionList))
            }
          })
        });
      }
    },
    addRuleClosed() {
      this.editPriceForm = {
        lower: "",
        upper: "",
        matchServerFee: "",
        matchServerFeeLimit: "",
      };
      this.$refs["editPriceRulesForm"].resetFields();
    },
    // 规则确定
    addRuleSure() {
      this.$refs["editPriceRulesForm"].validate((valid) => {
        if (valid) {
          let editPriceForm = {
            lower: Number(this.editPriceForm.lower),
            upper: Number(this.editPriceForm.upper),
            matchServerFee: Number(this.editPriceForm.matchServerFee),
            matchServerFeeLimit: Number(this.editPriceForm.matchServerFeeLimit),
          };
          if (editPriceForm.lower > editPriceForm.upper) {
            this.$message.error("起始值不能大于结束值");
            return;
          }
          if (editPriceForm.lower == editPriceForm.upper) {
            this.$message.error("起始值不能和结束值相等");
            return;
          }
          if(this.regionList.length && this.tableOperation !== 'edit'){
            for (let i = 0; i < this.regionList.length; i++) {
              const item = this.regionList[i];
              if (
                (editPriceForm.lower >= item.lower && editPriceForm.lower < item.upper) ||
                (editPriceForm.upper > item.lower && editPriceForm.upper <= item.upper) ||
                (editPriceForm.lower <= item.lower && editPriceForm.upper >= item.upper)
              ) {
                this.$message.error("区间有重复！");
                return;
              }
            }
          
          }
          let params = {
            lower: editPriceForm.lower,
            upper: editPriceForm.upper,
            matchServerFee: editPriceForm.matchServerFee,
            matchServerFeeLimit: editPriceForm.matchServerFeeLimit,
          };
          if (this.middleRowId.toString()) {
            this.regionList.splice(this.middleRowId, 1, params);
          } else {
            this.regionList.push(params);
          }
          this.regionList.sort((a, b) => Number(a.lower) - Number(b.lower));
          this.templateList.forEach(item => {
            if(item.templateName === this.tabsValue){
              item.regionList = JSON.parse(JSON.stringify(this.regionList))
            }
          })
          this.addRuleShow = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleSuccess() {
      this.templateList
      let params = {
        id: this.currentRow.id,
        templateList: this.templateList,
      }
      this.handleBtnLoading = true
      _api.saveServeTemplateList(params).then(res => {
        if(res.code === 1){
          this.$message.success(res.msg || '操作成功')
          this.close()
        }
      }).finally(() => {
        this.handleBtnLoading = false
      });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.together-dia {
  .flex {
    display: flex;
    align-items: center;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .rules-form {
    .rule-title {
      display: flex;
      align-items: center;
      color: #ff8080;
      margin-bottom: 20px;
    }
    .tip_ico {
      width: 14px;
      height: 14px;
      margin-right: 5px;
    }
    /deep/.el-input-group__append {
      border: 1px solid #0981ff;
      background: #0981ff;
      color: #ffffff;
    }
  }
  .label-S {
    min-width: 70px;
    text-align: right;
    margin-right: 20px;
    line-height: 32px;
    .label-must {
      color: #ff687b;
      margin-right: 4px;
    }
  }
}
</style>
